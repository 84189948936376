<template>
  <div v-if="info.name">
    <div class="subheader pb-5 subheader-transparent" id="kt_subheader">
      <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap py-0">
        <div class="d-flex align-items-center flex-wrap mr-1">
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <h5 class="text-dark font-weight-bolder my-1 mr-5">{{ info.name }}</h5>
            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bolder p-0 my-2 font-size-sm">
              <li class="breadcrumb-item">
                <router-link
                    to="/spaces"
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                >
                  <a :href="href" @click="navigate" class="text-muted">{{ $t('spaces.title') }}</a>
                </router-link>
              </li>
              <li class="breadcrumb-item">
                <span class="text-muted">{{ info.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loaded" class="container py-0">
      <div class="row">
        <!--begin::Aside-->
        <div class="col-lg-4" id>
          <!--begin::Profile Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Images-->
              <div class="d-flex">
                <div class="flex-shrink-0 mb-5 w-100">
                  <PuSkeleton height="290px" width="100%"></PuSkeleton>
                </div>
              </div>
              <!--end::Images-->
              <!--begin::Name-->
              <span class="font-size-h4 font-weight-bolder"><PuSkeleton></PuSkeleton></span>
              <!--end::Name-->
              <!--begin::Address-->
              <div class="d-flex flex-wrap mt-2 mb-5">
                <PuSkeleton width="80%"></PuSkeleton>
                <PuSkeleton></PuSkeleton>
              </div>
              <!--end::Address-->
              <!--begin::Contact-->
              <div class="mb-5">
                <div v-for="ind in 3" :key="ind" class="d-flex flex-wrap align-items-start mb-2">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 flex-shrink-0 mr-4">
                    <PuSkeleton width="40px" height="40px"></PuSkeleton>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Title-->
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                    <PuSkeleton :count="2"></PuSkeleton>
                  </div>
                  <!--end::Title-->
                </div>
              </div>
              <!--end::Contact-->
            </div>
            <!--end::Body-->
          </div>

          <!--begin::info Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <div>
                <PuSkeleton :count="5"></PuSkeleton>
              </div>
            </div>
            <!--end::Body-->
          </div>
          <!--end::info Card-->

          <!--begin::socials Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::socials-->
              <div>
                <div class="font-weight-bolder mb-4">{{ $t('spaces.info.socials') }}:</div>
                <PuSkeleton height="40px"></PuSkeleton>
              </div>
              <!--end::socials-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::socials Card-->

          <!--begin::policy Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <div>
                <div class="text-muted font-weight-bolder d-flex flex-column">
                  <PuSkeleton :count="2"></PuSkeleton>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
          <!--end::policy Card-->
        </div>
        <!--end::Aside-->
        <!--begin::Content-->
        <div class="col-lg-8">
          <!--begin::Card-->
          <div class="card gutter-b">
            <!--begin::Header-->
            <div class="card-header">
              <div class="card-title m-0">
                <h4 class="card-label font-weight-bolder text-dark m-0">{{ $t('spaces.info.about') }}</h4>
              </div>
            </div>
            <!--end::Header-->
            <div class="card-body pt-5">
              <p class="m-0">
                <PuSkeleton :count="5"></PuSkeleton>
              </p>
            </div>
          </div>
          <!--begin::Card-->
          <div class="card gutter-b">
            <!--begin::Header-->
            <div class="card-header">
              <div class="card-title m-0">
                <h4 class="card-label font-weight-bolder text-dark m-0">{{ $t('spaces.info.geolocation') }}</h4>
              </div>
            </div>
            <!--end::Header-->
            <div class="card-body pt-5">
              <div>
                <PuSkeleton width="300px"></PuSkeleton>
                <PuSkeleton width="500px"></PuSkeleton>
                <br>
                <br>
                <PuSkeleton :count="5"></PuSkeleton>
              </div>
            </div>
          </div>
          <!--begin::Card-->
          <div class="card gutter-b">
            <!--begin::Header-->
            <div class="card-header">
              <div class="card-title m-0">
                <h4 class="card-label font-weight-bolder text-dark m-0">{{ $t('spaces.info.infrastructure') }}</h4>
              </div>
            </div>
            <!--end::Header-->
            <div class="card-body pt-5">

              <PuSkeleton :count="5"></PuSkeleton>
            </div>
          </div>
        </div>
        <!--end::Content-->
      </div>
    </div>

    <div v-if="loaded" class="container py-0">
      <div class="row">
      <!--begin::Aside-->
        <div class="col-lg-4" id="kt_profile_aside">
          <!--begin::Profile Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::Images-->
              <div class="d-flex">
                <div v-if="info.images" class="flex-shrink-0 mb-5 w-100">
                  <VueSlickCarousel :arrows="true" :dots="false">
                    <div v-for="image in info.images" :key="image.id" class="w-100">
                      <img :src="image | photoUrl(setImageWidth)" class="w-100 h-100" style="object-fit: cover">
                    </div>
                    <template #prevArrow="arrowOption">
                      <div class="custom-arrow-left">
                        <i class="fas fa-chevron-circle-left"></i>
                      </div>
                    </template>
                    <template #nextArrow="arrowOption">
                      <div class="custom-arrow custom-arrow-right">
                        <i class="fas fa-chevron-circle-right"></i>
                      </div>
                    </template>
                  </VueSlickCarousel>
                </div>
              </div>
              <!--end::Images-->
              <!--begin::Name-->
              <span class="font-size-h4 font-weight-bolder">{{info.name}}</span>
              <!--end::Name-->
              <!--begin::Address-->
              <div class="d-flex flex-wrap mt-2 mb-5">
                <span href="#" class="text-muted font-weight-bolder mr-lg-8 mr-5 mb-2 mb-lg-0">
                  <font-awesome-icon icon="map-marker-alt" class="mr-1"/>
                  {{info.address_full}}
                </span>
              </div>
              <!--end::Address-->
              <!--begin::facilities-->
              <div class="mb-5">
                <span v-for="facility in info.facilities" :key="facility.id"
                    class="label font-weight-bold label-inline label-light-success mr-2 mb-2">{{facility.name}}</span>
              </div>
              <!--end::facilities-->
              <!--begin::Contact-->
              <div v-if="info.staff" class="mb-5">
                <div class="text-muted font-weight-bolder my-2">{{ $t('spaces.info.contacts') }}</div>
                <div v-for="staff in contacts" class="d-flex flex-wrap align-items-start mb-2">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 flex-shrink-0 mr-4">
                    <div v-if="staff.photo" class="symbol-label" :style="'background-image: url(\'' + staff.photo.url + '\')'"></div>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Title-->
                  <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                    <span href="#" class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{{staff.name}}</span>
                    <nl2br v-if="staff.info" v-for="(info, index) in staff.info" tag="p" :key="index" :text="info.text" class-name="text-muted font-weight-bold font-size-sm my-1" ></nl2br>
                    <a v-if="staff.phone" :href="'tel:' + staff.phone" class="text-muted font-weight-bold font-size-sm my-1">{{staff.phone}}</a>
                    <a v-if="staff.email" :href="'mailto:' + staff.email" class="text-muted font-weight-bold font-size-sm my-1">{{staff.email}}</a>
                  </div>
                  <!--end::Title-->
                </div>
              </div>
              <!--end::Contact-->
              <!--begin::opening_hours-->
              <div>
                <div class="text-muted font-weight-bolder my-2">{{ $t('spaces.info.opening_hours') }}</div>
                <div class="d-flex align-items-center justify-content-between mb-2" v-for="bh_time, index in businessHours()">
                  <span class="font-weight-bolder mr-2">{{ $t(index) }}</span>
                  <span class="font-weight-bolder text-primary">{{ bh_time }}</span>
                </div>
              </div>
              <!--end::opening_hours-->
            </div>
            <!--end::Body-->
          </div>

          <!--begin::info Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <span class="font-weight-bolder mr-2">{{ $t('spaces.info.area') }}:</span>
                  <span class="text-primary font-weight-bolder">{{ $t('spaces.info.area_value', {
                    min: info.office_short_info.area[0] || 0,
                    max: info.office_short_info.area[1] || 0,
                  }) }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <span class="font-weight-bolder mr-2">{{ $t('spaces.info.capacity') }}:</span>
                  <span class="text-primary font-weight-bolder">{{ $t('spaces.info.capacity_value', {
                    min: info.office_short_info.capacity[0] || 0,
                    max: info.office_short_info.capacity[1] || 0,
                  }) }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <span class="font-weight-bolder mr-2">{{ $t('spaces.info.cost') }}:</span>
                  <span class="text-primary font-weight-bolder">{{ $t('spaces.info.rent_value', {
                    cost: info.office_short_info.min_cost,
                    symbol: info.currency.symbol
                  }) }}</span>
                </div>
                <div v-if="meetingRoomsAmount" class="d-flex align-items-center justify-content-between">
                  <span class="font-weight-bolder mr-2">{{ $t('spaces.info.meeting_rooms') }}:</span>
                  <span class="text-primary font-weight-bolder">{{info.meeting_rooms_count}}</span>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
          <!--end::info Card-->

          <!--begin::socials Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <!--begin::socials-->
              <div>
                <div class="font-weight-bolder mb-4">{{ $t('spaces.info.socials') }}:</div>
                <a v-if="info.social_networks.instagram.url" :href="info.social_networks.instagram.url" target="_blank"
                   class="btn-icon btn-sm text-primary border-0 btn-light mr-3 my-1">
                  <font-awesome-icon :icon="['fab', 'instagram']"/></a>
                <a v-if="info.social_networks.vkontakte.url" :href="info.social_networks.vkontakte.url" target="_blank"
                   class="btn-icon btn-sm text-primary border-0 btn-light mr-3 my-1">
                  <font-awesome-icon :icon="['fab', 'vk']"/></a>
                <a v-if="info.social_networks.facebook.url" :href="info.social_networks.facebook.url" target="_blank"
                   class="btn-icon btn-sm text-primary border-0 btn-light mr-3 my-1">
                  <font-awesome-icon :icon="['fab', 'facebook-f']"/></a>
                <a v-if="info.social_networks.twitter.url" :href="info.social_networks.twitter.url" target="_blank"
                   class="btn-icon btn-sm text-primary border-0 btn-light mr-3 my-1">
                  <font-awesome-icon :icon="['fab', 'twitter']"/></a>
                <a v-if="info.social_networks.telegram.url" :href="info.social_networks.telegram.url" target="_blank"
                   class="btn-icon btn-sm text-primary border-0 btn-light mr-2 my-1">
                  <font-awesome-icon :icon="['fab', 'telegram-plane']"/></a>
              </div>
              <!--end::socials-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::socials Card-->

          <!--begin::policy Card-->
          <div class="card card-custom gutter-b">
            <!--begin::Body-->
            <div class="card-body">
              <div>
                <div class="text-muted font-weight-bolder d-flex flex-column">
                  <a v-if="info.policy"  :href="info.policy.url" class="text-muted mb-3">{{ $t('spaces.info.service_policy') }}</a>
                  <a v-for="document in info.documents" :href="document.url" class="text-muted mb-3">{{ document.name }}</a>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
          <!--end::policy Card-->
        </div>
      <!--end::Aside-->
      <!--begin::Content-->
        <div class="col-lg-8">
          <!--begin::Card-->
          <div class="card gutter-b">
            <!--begin::Header-->
            <div class="card-header">
              <div class="card-title m-0">
                <h4 class="card-label font-weight-bolder text-dark m-0">{{ $t('spaces.info.about') }}</h4>
              </div>
            </div>
            <!--end::Header-->
            <div class="card-body pt-5">
              <p class="m-0" v-html="info.description"></p>
            </div>
          </div>
          <!--begin::Card-->
          <div class="card gutter-b" id="address">
            <!--begin::Header-->
            <div class="card-header">
              <div class="card-title m-0">
                <h4 class="card-label font-weight-bolder text-dark m-0">{{ $t('spaces.info.geolocation') }}</h4>
              </div>
            </div>
            <!--end::Header-->
            <div class="card-body pt-5">
              <div>
                <div class="text-muted font-weight-bolder mb-1">{{ $t('spaces.info.address') }}</div>
                <div class="font-weight-bolder mb-5">{{info.address_full}}</div>
                <yandex-map :settings="{}" :coords="[info.lat, info.lon]" zoom="15" style="width:100%;height:300px">
                  <!--Markers-->
                  <ymap-marker
                      :coords="[info.lat, info.lon]"
                      :marker-id="info.id"
                      hint-content=""
                  />
                </yandex-map>
                <br>
                <div v-for="dd in driving_directions">
                  <div class="text-muted font-weight-bolder mb-2">{{dd.name}}:</div>
                  <div v-for="block in dd.blocks" class="d-flex flex-wrap mb-3">
                    <img v-if="block.type == 'img'"
                         :src="block.content.url" style="width:100%">
                    <p v-if="block.type == 'text'" v-html="block.content.text"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--begin::Card-->
          <div class="card gutter-b">
            <!--begin::Header-->
            <div class="card-header">
              <div class="card-title m-0">
                <h4 class="card-label font-weight-bolder text-dark m-0">{{ $t('spaces.info.infrastructure') }}</h4>
              </div>
            </div>
            <!--end::Header-->
            <div class="card-body pt-5">
              <b-tabs pills card vertical lazy
                      nav-class="p-0 mr-10"
                      nav-wrapper-class="p-0"
                      active-tab-class="p-0"
                      content-class="p-0">
                <b-tab v-for="(infstr, index) in infrastructure" :title="infstr.name" :key="index"
                       title-link-class="font-weight-bolder justify-content-center mb-5 text-hover-dark-25">
                  <b-card-text v-for="(block, index) in infstr.blocks" :key="index">
                    <img v-if="block.type == 'img'"
                         :src="setImgByScreenWidth(block.content.url)" style="width:100%">
                    <p v-if="block.type == 'text'" v-html="block.content.text"></p>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      <!--end::Content-->
      </div>
    </div>
  </div>
</template>


<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import Nl2br from 'vue-nl2br'
import {mapMutations, mapGetters} from 'vuex'
import api from '@/plugins/api'
import Spaces from "@/modules/spaces/views/Spaces";
let cookie = require('vue-cookie');

export default {
  components: {Spaces, Nl2br, VueSlickCarousel, yandexMap, ymapMarker },
  computed: {
    ...mapGetters({
      info: 'spaces/info',
      guide: 'spaces/guide',
      loaded: 'spaces/loaded'
    }),
    setImageWidth() {
      return Math.floor(window.innerWidth);
    }
  },
  data() {
    return {
      contacts: [],
      driving_directions: [],
      infrastructure: [],
      meetingRoomsAmount: false
    }
  },
  mounted() {
    this.fetch()
  },
  watch: {
    guide: {
      handler() {
        if (this.guide && this.guide.length > 0) {
          for (let i in this.guide) {
            // contacts
            if (this.guide[i].type == 'contact') {
              let photo = null
              for (let u in this.guide[i].blocks) {
                if (this.guide[i].blocks[u].type == 'img') {
                  photo = this.guide[i].blocks[u].content;
                }
              }
              let info = []
              for (let u in this.guide[i].blocks) {
                if (this.guide[i].blocks[u].type == 'text') {
                  info.push(this.guide[i].blocks[u].content)
                }
              }
              this.contacts.push({
                name: this.guide[i].name,
                photo: photo,
                info: info,
              })
            }

            // infrastructure
            if (this.guide[i].type == 'infrastructure') {
              this.infrastructure.push({
                name: this.guide[i].name,
                blocks: this.guide[i].blocks
              })
            }

            // driving_directions
            if (this.guide[i].type == 'driving_directions') {
              this.driving_directions.push({
                name: this.guide[i].name,
                blocks: this.guide[i].blocks
              })
            }


          }
        }
      },
      deep: true,
    }
  },
  methods: {
    fetch() {
      this.meetingRoomsAmount = window.__env.meeting_rooms_amount;
      this.$store.dispatch('spaces/GetInfo', this.$route.params.id)
    },
    businessHours(tariffs) {
      let day = ''
      let business_hours = {}
      if (this.info.business_hours.length > 0) {
        for (let i in this.info.business_hours) {
          if (this.info.business_hours[i].isActive) {
            switch (this.info.business_hours[i].index) {
              case 0:
                day = 'daysOfWeek.mon'
                break
              case 1:
                day = 'daysOfWeek.tue'
                break
              case 2:
                day = 'daysOfWeek.wed'
                break
              case 3:
                day = 'daysOfWeek.thu'
                break
              case 4:
                day = 'daysOfWeek.fri'
                break
              case 5:
                day = 'daysOfWeek.sat'
                break
              case 6:
                day = 'daysOfWeek.sun'
                break
            }
            business_hours[day] = `${this.info.business_hours[i].timeFrom} - ${this.info.business_hours[i].timeTill}`
          }
        }
        return business_hours
      }
      return null
    },
    setImgByScreenWidth(img) {
      return img.replace('300x-', '900x-')
    },
  }
}
</script>

<style>

</style>
